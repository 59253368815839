import React, { useEffect, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { Checkbox } from "@material-ui/core";
import { Shop } from "../../models";

const addShop = async (shop: Shop) => {
  await axios.post("/api/coffeeshops", shop);
};

const updateShop = async (shop: Shop) => {
  await axios.put("/api/coffeeshops", shop);
};

const deleteShop = async (id: string) => {
  await axios.delete(`/api/coffeeshops/${id}`);
};

const Admin = () => {
  const [shops, setShops] = useState<Shop[]>([]);
  const [user, setuser] = useState<any>({});

  useEffect(() => {
    async function fetchShops() {
      const res = await axios.get("/api/coffeeshops");
      setShops(res.data);
    }
    fetchShops();
  }, []);

  if (user?.isAdmin) {
    return renderContent(shops);
  } else {
    return renderSignin();
  }
};

const renderEditCheckbox = (props: any) => (
  <Checkbox
    checked={props.value || false}
    onClick={() => {
      props.onChange(!props.value);
    }}
  />
);

const renderContent = (shops: Shop[]) => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        title="Local Shops Admin"
        columns={[
          { title: "Shop Name", field: "name" },
          {
            title: "Shop Handle",
            field: "shopHandle",
            render: (rowData: Shop) => {
              return (
                <a
                  href={"https://instagram.com/" + rowData.shopHandle}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{rowData.shopHandle}
                </a>
              );
            },
          },
          { title: "Coordinates", field: "coordinates" },
          {
            title: "Google Maps Link",
            field: "link",
            render: (rowData: Shop) => {
              return (
                <a
                  href={rowData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {rowData.link}
                </a>
              );
            },
          },
          {
            title: "Is Roaster",
            field: "brewMethods.isRoaster",
            render: (rowData: Shop) => {
              return (
                <Checkbox checked={rowData.brewMethods?.isRoaster || false} />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has Cold Brew",
            field: "brewMethods.hasColdBrew",
            render: (rowData: Shop) => {
              return (
                <Checkbox checked={rowData.brewMethods?.hasColdBrew || false} />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has AeroPress",
            field: "brewMethods.hasAeroPress",
            render: (rowData: Shop) => {
              return (
                <Checkbox
                  checked={rowData.brewMethods?.hasAeroPress || false}
                />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has Steam Punk",
            field: "brewMethods.hasSteamPunk",
            render: (rowData: Shop) => {
              return (
                <Checkbox
                  checked={rowData.brewMethods?.hasSteamPunk || false}
                />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has French Press",
            field: "brewMethods.hasFrenchPress",
            render: (rowData: Shop) => {
              return (
                <Checkbox
                  checked={rowData.brewMethods?.hasFrenchPress || false}
                />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has Pour Over",
            field: "brewMethods.hasPourOver",
            render: (rowData: Shop) => {
              return (
                <Checkbox checked={rowData.brewMethods?.hasPourOver || false} />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has Nitro",
            field: "brewMethods.hasNitro",
            render: (rowData: Shop) => {
              return (
                <Checkbox checked={rowData.brewMethods?.hasNitro || false} />
              );
            },
            editComponent: renderEditCheckbox,
          },
          {
            title: "Has Siphon",
            field: "brewMethods.hasSiphon",
            render: (rowData: Shop) => {
              return (
                <Checkbox checked={rowData.brewMethods?.hasSiphon || false} />
              );
            },
            editComponent: renderEditCheckbox,
          },
          { title: "id", field: "_id", hidden: true },
        ]}
        data={shops}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                console.log(newData);
                addShop(newData);
                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                updateShop(newData);
                resolve();
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                deleteShop(oldData._id);
                console.log(oldData);
                resolve();
              }, 1000);
            }),
        }}
      />
    </div>
  );
};

const renderSignin = () => {
  return (
    <div className="center">You must be authorized to view this page!</div>
  );
};

export default Admin;
