import React, { useEffect, useState } from "react";
import axios from "axios";
import ShopInfo from "../ShopInfo";
import { Shop } from "../../models";
import ReactDOMServer from "react-dom/server";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import coffeeloading from "../../assets/coffeeloading.gif";

const Wrapper = styled.main`
  width: 100%;
  height: 500px;
`;

// Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
//todo: move this out
const handleApiLoaded = (map: any, maps: any, shops: Shop[]) => {
  const markers: any[] = [];
  const infowindows: any[] = [];

  // Close all open infowindows if you click on the map
  map.addListener("click", () => {
    infowindows.forEach((infowindow) => infowindow.close());
  });

  // Create markers and infowindows
  shops.forEach((shop) => {
    const coordinates = shop.coordinates.split(",");
    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);

    markers.push(
      new maps.Marker({
        position: {
          lat,
          lng,
        },
        map,
      })
    );

    infowindows.push(
      new maps.InfoWindow({
        content: ReactDOMServer.renderToString(<ShopInfo shop={shop} />),
      })
    );
  });

  markers.forEach((marker, i) => {
    marker.addListener("click", () => {
      infowindows.forEach((infowindow) => infowindow.close());
      infowindows[i].open(map, marker);
    });
  });
};

const Map = (props: any) => {
  const [shops, setShops] = useState<Shop[]>([]);

  useEffect(() => {
    async function fetchShops() {
      const res = await axios.get("/api/coffeeshops");
      setShops(res.data);
    }
    fetchShops();
  }, []);

  if (shops?.length) {
    const { lat, lng } = props.location;
    return (
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY || "" }}
          defaultZoom={15}
          center={{ lat, lng }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }: any) =>
            handleApiLoaded(map, maps, shops)
          }
        ></GoogleMapReact>
      </Wrapper>
    );
  }
  return <img className="center" src={coffeeloading} alt="loading"></img>;
};

export default Map;
