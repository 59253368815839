import React, { useState } from "react";
import Map from "./map/Map";
import LocationInput from "./LocationInput";
import { Location } from "../models";

const LocalShops = () => {
  const [location, setLocation] = useState<Location>({
    lat: 40.760546,
    lng: -73.990777,
  });

  return (
    <div className="App">
      <LocationInput onLocationChange={setLocation} />
      <Map location={location} />
    </div>
  );
};

export default LocalShops;
