import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import { auth, provider } from "../firebase";

const LoginButton = () => {
  let location = useLocation();
  if (location.pathname === "/admin") {
    return (
      <li>
        <button onClick={handleLogin}>Login With Google</button>
      </li>
    );
  }
  return null;
};

const handleLogin = async () => {
  await auth.signInWithRedirect(provider);
};

const Header = (props: any) => {
  /** SALVAGED REDUX ACTIONS */
  // export const fetchUser = () => async (dispatch: Dispatch) => {
  //   const res = await axios.get('/api/current_user');
  // export const setToken = (token: string) => async (dispatch: Dispatch) => {
  //   const FBIdToken = `Bearer ${token}`;
  //   localStorage.setItem('FBIdToken', FBIdToken);
  //   axios.defaults.headers.common['Authorization'] = FBIdToken;
  // }

  // };
  // useEffect(() => {
  //   const result = await auth.getRedirectResult();
  //   const idToken = await result.user?.getIdToken()
  //   console.log(`idToken: ${idToken}`);
  //   if (idToken) {
  //     await axios.post('/api/setCustomClaims', { idToken });
  //     this.props.setToken(idToken);
  //   }

  //   const idTokenResult = await auth.currentUser?.getIdTokenResult();
  //   // Confirm the user is an Admin.
  //   console.log(idTokenResult);
  //   if (idTokenResult?.claims.admin) {
  //     console.log('IS ADMIN');
  //     // Show admin UI.
  //     props.setAdmin(true);
  //   } else {
  //     console.log('IS NOT ADMIN');
  //     // Show regular user UI.
  //     props.setAdmin(false);
  //   }
  // });

  const logout = () => {
    console.log("LOGOUT");
    auth.signOut(); //todo this is a promise
  };

  const renderContent = () => {
    switch (props.user?.isAdmin) {
      case null || undefined:
        return;
      case false:
        return <LoginButton />;
      default:
        return [
          <li key="1">{props.user?.isAdmin ? "Logged in as admin" : ""}</li>,
          <li key="2">
            <a href="/admin" onClick={logout}>
              Logout
            </a>
          </li>,
        ];
    }
  };

  return (
    <div className="nav-wrapper center ">
      <Link to={props.user ? "/admin" : "/"} className="center brand-logo">
        <img className="brand-logo center" src={logo} alt="coffeeforyou"></img>
      </Link>
      <ul className="right">{renderContent()}</ul>
    </div>
  );
};

export default Header;
