import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.scss";

import Header from "./Header";
import LocalShops from "./LocalShops";
import Admin from "./admin/Admin";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  return (
    <BrowserRouter>
      <div className="container">
        <Header />
        <Route exact path="/" component={LocalShops} />
        <Route exact path="/admin" component={Admin} />
      </div>
    </BrowserRouter>
  );
};

export default App;
