//import serviceAccount from '../config/local-shops-develop-firebase-adminsdk-3l124-5f88aa968f.json';

// /** dev */
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// export const firebaseConfig = {
//     apiKey: "AIzaSyD-2dNwsHYLcxhWn0zMND3KfaSNafCipCY",
//     authDomain: "local-shops-develop.firebaseapp.com",
//     projectId: "local-shops-develop",
//     storageBucket: "local-shops-develop.appspot.com",
//     messagingSenderId: "475568145524",
//     appId: "1:475568145524:web:6ca2c123684c02b3217d21",
//     measurementId: "G-TLCK9LFJ53"
// };

/** prd */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyC1pNx7uieYklMbXmHGuJl1XI-_EAkfNMA",
    authDomain: "local-shops-prod-89d07.firebaseapp.com",
    projectId: "local-shops-prod-89d07",
    storageBucket: "local-shops-prod-89d07.appspot.com",
    messagingSenderId: "318050741149",
    appId: "1:318050741149:web:f8b473bf6c4fe016a65d74",
    measurementId: "G-452M9PZBYT"
  };

const adminSdk = {
    "type": "service_account",
    "project_id": "local-shops-develop",
    "private_key_id": "5f88aa968f84bbf6232e68656210274965696b69",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCfQU7Pmv+S4s6k\nzV46M65kuhysezrt0NzmEL5lZJKIvPLL3VI7F8WFnrIGtPXhP5vWNJ/oJ6lZT1wn\nfXfBgeH/vd8sEo2ixVwVKS9pHaonlVTHWQwl5f4m3/WN8UrzEN2zT29IA053xOpB\nKm3gd4WFgsePTZEdQ4CRUfSxZuQrNL7yQeAtHibErud8t1m3SIc/5bzFv5QqPIAL\nXu+GnETx8tlSoxPLDDFyDkA/SRvHRg71e1fJ7nIzzrbqy8D9N4Vq0R0XP/MfILa+\nmiFgenyyt6adlCA9hLfkfNuNdgp84/u6bAWhDWVlo+EesNWy/w79RVualH14Gkc7\nijakk3etAgMBAAECggEACqRZ1fWGMhnYvrpgMKn5nk6x3KnLHToO5OCGD0RPpsu0\n5YAiPwN+kMhdyPpzOtczZ0vyypf0kQdaAbk4lTRZdpZI0UsNGW0wUylX0Hb/GEWP\nmdb+wOfpWk4u0cp9HG3frsbhCWd6fnY+GYGHwE9PBhtgPAMuLmMvdnvgStw0ukP2\nijEf87oy/wlAib1DVY7tM3hvpKAZBugtEjLuLhUBKrpBsB1m2C3gvwAcscWk8jQm\nLK8srpa77J1gIKXyRubMJqMp1IglLoaLL1X/yJKHv7XQSS4qta3OebTowGFPyTnT\nsB8GPbIS0juhwwdEmOeDgeYgOhKmcZ/WMY2s+AURuQKBgQDd/PY5xEPjaZ1XLRd9\nEXNPcroVqIf1ESdzKwG96X2ZcYFzCf5x197MAVfO70SAvE1D0jL2ZHnh5XgUyfAP\nE4csCAJmln2UsUhL4om2x5xVi6RiBedoerDkkEhH6+7x2CLIwWyutfcf8XEDFZMZ\n7JhXxksXm4jEx9aYd2tPXDbLKQKBgQC3p8TyMNzy35i3vBhQf0GzX0a9e/qKmrCn\nlynQVqzfR4+LBUrhvUc/Yh3r4qwWM8/ZC5kCAZqghsTqHVi3N4DK8Gvd7ysHwRjM\naLYcUkPDdmy1qPI0BYig3dwT7aH+1pdIL2lnqaH/dwvYB5q0Gjg4PEtKuLx0gj5i\nNfIWIMVc5QKBgGT1nU5ccrvE1uhE+7/H5femJ/edtGfCq3x3fM1QmezlcIhPbUd9\ndDi4C6VzsGYUPdewi7j/rWcNo/VgeiuBKCLgaacanna3wjexCgz076cAYXyrTrl3\nw2fQLu924C9hq0A+qB01aPVAK7PJZiOONSMGbjSIakWE1ZZ2CXoIaGsxAoGAD2eC\nDHJuxexdSeP8Ahx6bUori88vV5rcAGKcfj4W5+9/qcB65G5U4IshSVzM5FvaLjFb\n1yA9UQOc1qo5e7yrD9qUoZvi9xaKNvL9e2inCGkRezc9gYGJUTzqrb073j0vmT1o\n0OoYjW31z1vr7PlaB9V+1zihjgAyrVgrAxIim8UCgYANib0s1kuIQesoaoxdUtw4\nmGL9l23OOQucvlXo+xUjnJoMb6YL2X4t2jASzi9NH2gCDTHHzjFuMzJkT6DmzovU\n6Sz5zwl/1qp+GnnkM6FTx9/HsLNxyW0VwTvIn4bb+QXtsPtr0oKpAnFftS/J57ug\nZW5320EHeqvFBuRiiAZDyg==\n-----END PRIVATE KEY-----\n",
    "client_email": "firebase-adminsdk-3l124@local-shops-develop.iam.gserviceaccount.com",
    "client_id": "115725662413824734069",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-3l124%40local-shops-develop.iam.gserviceaccount.com"
}