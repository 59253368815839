import React from 'react';
import { Shop } from '../models';
import espresso from '../assets/espresso.png';
import siphon from '../assets/siphon.png';
import instagramGlyph from '../assets/instagram.svg';

//todo
interface ShopProps {
  shop: Shop;
}

const ShopInfo = (props: ShopProps) => {
  return (
    <div className="ShopInfo">
      <h5>{props.shop.name}</h5>
      <img className="shop-image mrl-5" src={espresso} alt="espresso"></img>
      <img className="shop-image mrl-5" src={siphon} alt="siphon"></img>
      <p></p>
      <div>
        <img
          className="social-logo"
          src={instagramGlyph}
          alt="instagramLogo"
        />
        <a
          href={'https://instagram.com/' + props.shop.shopHandle}
          target="_blank"
          rel="noopener noreferrer"
        >
          @{props.shop.shopHandle}
        </a>
      </div>
    </div>
  );
}

export default ShopInfo;
