import axios from "axios";
import React from "react";
import { Location } from "../models";

const fetchLocationInfo = async (zipcode: string) => {
  const res = await axios.get(`/api/geocode/coordinates/${zipcode}`);
  let locationInfo: Location = res.data.results[0].geometry.location;
  return locationInfo;
};

const LocationInput = (props: any) => {
  const handleKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      //todo: validate zipcode
      let locationInfo = await fetchLocationInfo(event.target.value);
      props.onLocationChange(locationInfo);
    }
  };

  return (
    <div className="input-field">
      <input
        id="search_address"
        type="text"
        className="validate location-input center"
        placeholder="enter zipcode"
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default LocationInput;
